export const SEARCH_REPORTS_FRESH = 'digitalStore/reports/SEARCH_REPORTS_FRESH'
export const SEARCH_REPORTS_NEXT = 'digitalStore/reports/SEEARCH_REPORTS_NEXT'
export const RESET_STORE = 'digitalStore/reports/RESET_STORE'
export const CHANGE_REPORTS_FILTER = 'digitalStore/reports/CHANGE_REPORTS_FILTER'
export const CHANGE_REPORTS_FILTERS = 'digitalStore/reports/CHANGE_REPORTS_FILTERS'
export const FETCH_REPORT_FOR_ID = 'digitalStore/reports/FETCH_REPORT_FOR_ID'
export const DELETE_REPORT_BY_ID = 'digitalStore/reports/DELETE_REPORT_BY_ID'
export const FETCH_REPORTS_BY_EXT_ID = 'digitalStore/reports/FETCH_REPORTS_BY_EXT_ID'
export const DELETE_DRAFT_REPORT = 'digitalStore/reports/DELETE_DRAFT_REPORT'
export const FETCH_ANONYMISED_REPORTS_FOR_IDS = 'digitalStore/reports/FETCH_ANONYMISED_REPORTS_FOR_IDS'
