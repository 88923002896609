import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { translations } from '../../../config'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { actions as formViewActions } from '../../../store/modules/formViews'
import { selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { useSelector } from 'react-redux'
import Editable from '../../../components/Editable'
import ReportForm from './components/ReportForm/ReportForm'
import { Grid } from '@material-ui/core'
import ReviewModal from '../../CaseManagement/CaseSearchScreen/Modals/ReviewModal'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import modalService from '../../../services/modalService'
import Button from '../../../components/Button'
import Notification from './components/Notification'
import toastService from '../../../services/toastService'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { reviewStatuses } from '../../../store/modules/submissionReports/constants'
import { withStyles, styled } from '@material-ui/core/styles'
import { useExistingReportErrors } from '../hooks'
import style from './style'
import digitalStoreSdk from '../../../digitalStoreSdk'
import ReviewInfo from '../../../components/ReviewInfo'
import ReportScreenBase from './NewReportBeta'

const ReviewReportScreen = Editable(({ classes, location, match }) => {
  const dispatch = useDispatch()
  const submissionReportId = _.get(match, 'params.submissionReportId')
  const reportId = _.get(match, 'params.reportId')
  const followUpNotificationId = _.get(match, 'params.notificationId')
  const isWorkspace = useSelector(authSelectors.isLoggedIntoWorkspace)
  const selectedOrganisation = useSelector(authSelectors.getSelectedOrganisation)
  const skipReadOnlyView = useSelector(platformSelectors.getHasSkipReportReadOnlyViewEnabled)

  const existingReport = useSelector(reportDetailsSelectors.getExistingReport)

  const [formState, setFormState] = useState()
  const [isLoading, setIsLoading] = useState()
  const [initialValues, setInitialValues] = useState()
  const [receiverStatus, setReceiverStatus] = useState()
  const [organisationDetails, setOrganisationDetails] = useState()
  const [productDetails, setProductDetails] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const isReportEditable = useSelector(reportDetailsSelectors.getIsReportEditable)
  const [isNewReport, setIsNewReport] = useState(false)
  const [reportReview, setReportReview] = useState({})
  const [anonymisedReport, setAnonymisedReport] = useState()
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      const report = await digitalStoreSdk.submissions.fetchSubmissionReportById({
        submissionReportId,
        organisationId: selectedOrganisation.id
      })
      setReceiverStatus(report.receiverStatus)
      if(report.receiverStatus !== 'IN_REVIEW') {
      const reportReview = await digitalStoreSdk.submissions.fetchSubmissionReportReviewById({ submissionReportId })
      if (reportReview) {
        setReportReview(reportReview)
      }
     }
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching report data:', error)
    }
  }
  useExistingReportErrors(existingReport)

  useEffect(() => {
    if (reportId) {
      if (reportId !== 'new') {
        setIsReadOnly(!skipReadOnlyView)
      } else {
        setIsNewReport(true)
      }
    }
    setIsReadOnly(true)
  }, [reportId])

  useEffect(() => {
    const product = _.get(location, 'state.product')
    if (product) {
      setProductDetails(product)
    }
    if (!isWorkspace && selectedOrganisation) {
      setOrganisationDetails(selectedOrganisation.details)
    }
  }, [location])

  useEffect(() => {
    if (submissionReportId && selectedOrganisation) {
      fetchReport()
    }

    return () => {
      dispatch(formViewActions.selectReport(null))
    }
  }, [])

  const onReviewSuccess = useCallback(async () => {
    await fetchReport()
  }, [fetchReport])

  const onFormStateChange = useCallback(
    (state) => {
      setFormState(state)
    },
    [setFormState]
  )

  const onReject = useCallback(() => {
    modalService.open({
      component: ReviewModal,
      title: translations('Report Review - Confirmation - Title - Reject'),
      message: translations('Report Review - Confirmation - Message - Reject'),
      modalIndex: 2,
      receiverStatus,
      reviewStatus: reviewStatuses.REJECT,
      submissionReportId,
      onReviewSuccess
    })
  }, [modalService])

  const onApprove = () => {
    modalService.open({
      component: ReviewModal,
      title: translations('Report Review - Confirmation - Title - Approve'),
      message:
        receiverStatus === 'REJECTED'
          ? translations('Report Review - Confirmation - Message - Revert Rejection')
          : translations('Report Review - Confirmation - Message - Approve'),
      modalIndex: 2,
      receiverStatus,
      reviewStatus: reviewStatuses.APPROVE,
      submissionReportId,
      onReviewSuccess
    })
  }

  const onSuccess = (props) => {
    const extReportId = _.get(props, 'response.report.extReportId')
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Success submitting report', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error submitting report', { error: error.message }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftSuccess = (props = {}) => {
    const { report: { extReportId } = {} } = props
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Draft Success', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error saving draft', { error }),
      autoHideDuration: 6000
    })
  }

  const RejectButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white'
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.error.dark}`
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      color: 'white'
    }
  }))

  const ApproveButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.success.dark}`
    },
    '&:active': {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      color: 'white'
    },
    marginRight: '20px'
  }))

  const ReviewActions = (
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff',
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          borderTop: '1px solid #e0e0e0'
        }}
      >
        {/* Centered buttons */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flex: 1
          }}
        >
          {receiverStatus === 'IN_REVIEW' && _.isEmpty(reportReview) && (
            <>
              <ApproveButton variant='outlined' onClick={onApprove} aria-label={translations('Report Review - Approve')}>
                {translations('Report Review - Approve')}
              </ApproveButton>
              <RejectButton variant='outlined' onClick={onReject} aria-label={translations('Report Review - Reject')}>
                {translations('Report Review - Reject')}
              </RejectButton>
            </>
          )}
        </div>
      </div>
  )

  const ReportFormComponent = (
    <ReportForm
      isWorkspace={isWorkspace}
      onFormStateChange={onFormStateChange}
      onSuccess={onSuccess}
      onError={onError}
      onSaveDraftSuccess={onSaveDraftSuccess}
      onSaveDraftError={onSaveDraftError}
      initialValues={initialValues}
      organisationDetails={organisationDetails}
      productDetails={productDetails}
      isReadOnly={isReadOnly}
      isNewReport={isNewReport}
      anonymisedReport={anonymisedReport}
    />
  )

  const ReportFormRender = (
    <div>
      {!isLoading && followUpNotificationId ? (
        <Grid container alignItems='center'>
          {/* Check if review data exists */}
          {!_.isEmpty(reportReview) && (
            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.scrollableGridItem}>
              <Notification notificationId={followUpNotificationId} />
            </Grid>
          )}

          {/* Center the main form when the left column is not present */}
          <Grid
            item
            xs={12}
            sm={!_.isEmpty(reportReview) ? 8 : 12}
            md={!_.isEmpty(reportReview) ? 9 : 8}
            lg={!_.isEmpty(reportReview) ? 9 : 6}
            container
            justifyContent={!_.isEmpty(reportReview) ? 'flex-start' : 'center'}
          >
            <div className={classes.formContainer}>{!isLoading && ReportFormComponent}</div>
          </Grid>
        </Grid>
      ) : (
          <Grid container>
            {!_.isEmpty(reportReview) && (
              <Grid item xs={12} sm={4} md={3} lg={3} className={classes.scrollableGridItem}>
                <ReviewInfo reportReview={reportReview} className={classes.reviewInfoPanel} isLoading={isLoading} onApprove={onApprove} />
              </Grid>
            )}
            {/* Main form: Centered if no review panel */}
            <Grid
              item
              xs={12}
              sm={!_.isEmpty(reportReview) ? 8 : 12}
              md={!_.isEmpty(reportReview) ? 9 : 12}
              lg={!_.isEmpty(reportReview) ? 9 : 12}
              className={classes.scrollableGridItem}
            >
              <div className={classes.formContainer}>{!isLoading && ReportFormComponent}</div>
            </Grid>
          </Grid>
      )}
      {ReviewActions}
    </div>
  )

  return <ReportScreenBase mode={'review'} fetchReport={fetchReport} ReportFormRender={ReportFormRender}/>
})

export default withStyles(style)(ReviewReportScreen)
